<template>
    <section>
        <button class="btn btn-primary" v-b-modal.blockDate> Ajouter une fermeture de date</button>
        <b-modal id="blockDate" title="Indiquer une fermeture" hide-footer>
            <div class="card">
                <div class="card-body">
                    <p>Sélectionner la date de début et date fin, ensuite l'heure de début et l'heure de fin.</p> 
                    <p class="text-muted">S'il s'agit d'une même journée, sélectionner deux fois la même date</p>
                    <vc-date-picker v-model="date" mode="datetime" :model-config="modelConfig" is-range is24hr/>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-end">
                    <div class="btn-link btn mx-4" @click="$bvModal.hide(blockDate)">Annuler</div>
                    <button class="btn btn-primary" @click="blockDate()">Confirmer</button>
                </div>
                <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"block-date",
    data(){
        return{
            date: {
                start:'',
                end:'',
                schedule:''
            },
             modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DDTHH:mm:ss', // Uses 'iso' if missing
            },
            isload: false,
            unavailableschedule:[]
        }
    },
    methods:{
        async blockDate(){
            this.isload=true
            const bool = await this.getschedule()
            this.loadershow = true
            if (bool) {
                let text = ""
                this.unavailableschedule.forEach(element => {
                    text+=" "+element.startDate+" "+element.endDate
                });
                console.log(text);
                this.loadershow =false
                this.$swal({
                    title: 'Une réservation a été faite dans cette fermeture de date, Merci de bien vérifier l\'agenda',
                    showCancelButton: true,
                    confirmButtonColor: '#0b0b0b',
                    cancelButtonColor: '#fff',
                    // cancelButtonText: 'Annuler',
                    confirmButtonText:'Actualiser'
                })
                .then(() => location.reload())
                return
            }
            if(!this.date.start || !this.date.end){
                alert('vous devez sélectionner un horaire')
                return
            }
            this.isload= true
            axios.post('purchases',{
                //ulid de l'order contenu les fermetures
                order:"01FY46XE8PN27S18ZJYS2GEFKZ",
                // ulid du service de fermeture
                service:"01FY46PE2Y9N67CFS9ZEFW05DH",
                quantity:1,
                datestart:this.date.start,
                dateend:this.date.end
            },{
                headers:{
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then(resblockDate => {
                console.log('post block date',resblockDate)
                this.$bvModal.hide('blockDate')
                location.reload()
                })
            .catch(err => console.log(err))
        },
        
        async getschedule(){
            this.loadershow =true
            this.message ="Verification des dates"
             await axios.get('datescheduledservices')
            .then(
                resschedule => {
                    console.log({resschedule})
                    this.schedule =resschedule.data.result
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )

            if(this.schedule){
                    this.schedule.forEach(scheduleobject => {
                        if (
                            this.dateToNumber(this.date.start) >= this.dateToNumber(scheduleobject.datestart)  &&  
                            this.dateToNumber(this.date.start) <= this.dateToNumber(scheduleobject.dateend)  ||
                            this.dateToNumber(this.date.end)  >= this.dateToNumber(scheduleobject.datestart)  &&
                            this.dateToNumber(this.date.end) <= this.dateToNumber(scheduleobject.dateend) ||
                            this.dateToNumber(this.date.start) <= this.dateToNumber(scheduleobject.datestart) &&
                            this.dateToNumber(this.date.end) >= this.dateToNumber(scheduleobject.dateend)
                            ) 
                        {
                            this.unavailableschedule.push(this.date)
                        }
                    });
            }
            this.message="Traitement des données en cours"
            if (this.unavailableschedule.length > 0 ) {
                return true
            }
            if(this.unavailableschedule.length == 0){
                return false
            }
        },
        dateToNumber(stringDate){
            let date = stringDate.substr(0,16)
            date = date.replace('-','')
            date = date.replace('T','')
            date = date.replace(':', '')
            date = date.replace('-','')
            date = date.replace(' ','')
            date = Number(date)
            return date
        },
        dateString(mydate){
            var stringdate;
            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
    }
}
</script>