<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="full-calendar-app">
                            <div v-if="loading">En cours de chargement ...</div>
                            <template v-else>
                                <div class="d-flex justify-content-end">
                                    <block-date />
                                    <button class="btn btn-primary mx-4" @click="reservation()">Réserver</button>
                                </div>
                                <full-calendar :events="events" :config="config" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/locale/fr'
import axios from 'axios';
import BlockDate from '../../components/functions/block-date.vue';

export default {
    name: 'agenda',
    components: {
        FullCalendar,
        BlockDate
    },
    data () {
    return {
      purchases:[],
      service:[],
      events: [],
      loading: true,
      config: {
          locale: 'fr',
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'month,basicWeek,basicDay'
        },
        defaultDate: this.dateString(),
        defaultView: 'list',
        navLinks: false,
        editable: false,
        eventLimit: false,
        eventClick:this.handleEventClick
      }
    }
    
  },
  methods:{
      getPurchases(){
          axios.get('purchases',{headers:{'X-AUTH-TOKEN':localStorage.getItem('token')} })
                .then(
                    respurchases => {
                        this.purchases =respurchases.data.result.filter(ser => ser.service != null);
                        this.createEvent(this.purchases)    
                        this.loading = false
                        
                    }
                )
                .catch(
                    errpurchases=> {
                        errpurchases
                    }
                )
      },
      createEvent(data){    
          data.forEach(element => {
              this.events.push({
                id:element.order.ulid,
                title: this.getServiceName(element.service.ulid)+' '+ element.order.user.firstname + ' '+element.order.user.lastname,
                start: element.datestart,
                end: element.dateend
              })
          })
          this.loading = false // solution de secours
      },
      dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) 

            return stringdate
      },
      digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
      },
      getService() {
        axios.get('services')
        .then(resService=> {
        this.service= resService.data.result
        this.getPurchases() 
    
        })
        .catch(errService=> console.log(errService))
      },
      getServiceName(ulid){
          let serv = this.service.filter(ser => ser.ulid == ulid)
          return serv[0].name
      },
      reservation(){
          this.$router.push({
              name:'reservation'
          })
      },
      handleEventClick(info){
          console.log({info})
          this.ordersDetails(info.id)
      },
       ordersDetails(ulid) {
        this.$router.push({
          name:"ordersDetails",
          params: {
            ulid: ulid
          }
        })
      },
      
      
  },
//   mounted(){
//       this.getService()
//       this.getPurchases()
//   },
  created(){
      this.getService()
  },
}
</script>